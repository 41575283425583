<template>
  <div>
    <!-- Бүх хуудсанд ашиглагдах header section -->
    <el-row :gutter="0">
      <el-col :span="20" :offset="2">
        <header class="page-header">
          <el-row :gutter="20" align="middle">
            <el-col :span="12">
              <h2>Татан авалтын жагсаалт</h2>
            </el-col>
            <el-col :span="12">
              <div class="actions text-right">
                <el-badge :value="this.requiredTotal" class="item">
                  <el-button type="default" @click="visibleProductDialog = true">Татан авах шаардлагатай бараа</el-button>
                </el-badge>
                <router-link to="/add-transfer">
                  <el-button type="success" class="item">Нэмэх</el-button>
                </router-link>
              </div>
            </el-col>
          </el-row>
        </header>
        <!-- Tabled content -->
        <div class="panel tabled">
          <el-tabs v-model="activeTabStatus" @tab-click="onClickTab">
            <el-tab-pane :label="tab.label" :name="tab.value" v-for="(tab, indexTab) in tabData" :key="indexTab">
              <div class="panel-content">
                <div class="pl20 pr20 table-filter mb10">
                  <el-row :gutter="10" align="middle" type="flex">
                    <el-col :span="20">
                      <el-input placeholder="Хайх" class="input-with-select" v-model="search" @input="onSearch">
                        <el-button
                          slot="prepend"
                          icon="el-icon-search"
                        ></el-button>
                      </el-input>
                    </el-col>
                  </el-row>
                </div>
                <transfer-table :transfers="transfers" :isLoading="isLoading" :pageSize="pageSize" :totalCount="totalCount" :currentPage="currentPage" :sizeChangeHandler="sizeChangeHandler" :curentPageHandler="curentPageHandler"></transfer-table>
              </div>
            </el-tab-pane>
          </el-tabs>
        </div>
      </el-col>
    </el-row>
    <get-product-dialog v-if="visibleProductDialog" :visible-product-dialog.sync="visibleProductDialog"></get-product-dialog>
  </div>
</template>

<script>
// // @ is an alias to /src
import getProductDialog from './components/getProductDialog'
import services from '../../../helpers/services'
import TransferTable from './components/transferTable'
export default {
  name: 'transferList',
  components: {
    // MenuIcon
    TransferTable,
    // eslint-disable-next-line vue/no-unused-components
    getProductDialog
  },
  data () {
    return {
      from: '',
      size: '',
      totalCount: 0,
      currentPage: 1,
      products: [],
      visibleProductDialog: false,
      pageSize: 20,
      productPageSize: 20,
      productCurrentPage: 1,
      productFrom: 0,
      productSize: 20,
      search: '',
      status: 'all',
      requiredTotal: '',
      tableData: null,
      transfers: [],
      isLoading: false,
      activeTabStatus: 'all',
      statusFilter: '',
      statusFilterOptions: [
        {
          value: 'all',
          label: 'All'
        },
        {
          value: 'draft',
          label: 'Draft'
        },
        {
          value: 'archived',
          label: 'Archived'
        }
      ],
      options: [
        {
          value: 'Нэр А - Я',
          label: 'Нэр А - Я'
        },
        {
          value: 'Нэр Я - А',
          label: 'Нэр Я - А'
        },
        {
          value: 'Бүртгэгдсэн огноо буурах',
          label: 'Бүртгэгдсэн огноо буурах'
        },
        {
          value: 'Бүртгэгдсэн огноо өсөх',
          label: 'Бүртгэгдсэн огноо өсөх'
        },
        {
          value: 'Агуулахад цөөн',
          label: 'Агуулахад цөөн'
        },
        {
          value: 'Агуулахад их',
          label: 'Агуулахад их'
        }
      ],
      tabData: [
        {
          label: 'All',
          value: 'all'
        }, {
          label: 'Pending',
          value: 'pending'
        }, {
          label: 'Partial',
          value: 'partial'
        }, {
          label: 'Completed',
          value: 'complete'
        }
      ]
    }
  },
  mounted () {
    var from = (this.pageSize * (this.currentPage - 1))
    var size = this.pageSize
    if (this.$route.query.page && this.$route.query.size && this.$route.query.search && this.$route.query.tab) {
      const incomingPage = parseFloat(this.$route.query.page)
      const incomingSize = parseFloat(this.$route.query.size)
      const incomingSearch = this.$route.query.search
      const incomingTab = this.$route.query.tab
      this.currentPage = incomingPage
      this.pageSize = incomingSize
      this.search = incomingSearch
      this.tab = incomingTab
      from = (this.pageSize * (incomingPage - 1))
      size = incomingSize
    }
    this.from = from
    this.size = size
    this.getTransfers(from, size, this.search)
  },

  methods: {
    onSearch () {
      this.$router.push({ name: 'transfer', query: { page: this.currentPage, size: this.pageSize, search: this.search, tab: this.activeTabStatus } })
      this.getTransfers(this.pageSize * (this.currentPage - 1), this.pageSize, this.search, this.activeTabStatus)
    },

    sizeChangeHandler (item) {
      this.pageSize = item
      this.$router.push({ name: 'transfer', query: { page: this.currentPage, size: this.pageSize, search: this.search, tab: this.activeTabStatus } })
      this.getTransfers((this.pageSize * (this.currentPage - 1)), this.pageSize, this.search, this.activeTabStatus)
    },

    curentPageHandler (item) {
      this.currentPage = item
      this.$router.push({ name: 'transfer', query: { page: item, size: this.pageSize, search: this.search, tab: this.activeTabStatus } })
      this.getTransfers((this.pageSize * (this.currentPage - 1)), this.pageSize, this.search, this.activeTabStatus)
    },

    getTransfers (from, size) {
      const query = '?status=' + this.status + '&search_text=' + this.search + '&from=' + from + '&size=' + size
      this.isLoading = true
      services.getTransfers(query).then(response => {
        this.transfers = response.datas
        this.totalCount = response.total
        this.requiredTotal = response.required_total
        this.isLoading = false
      })
    },

    onClickTab (data) {
      if (data.label === 'All') {
        this.status = ''
      } else if (data.label === 'Pending') {
        this.status = 'pending'
      } else if (data.label === 'Partial') {
        this.status = 'partial'
      } else if (data.label === 'Completed') {
        this.status = 'complete'
      }
      this.getTransfers(this.from, this.size, this.search)
    }
  }
}
</script>
<style>
.el-table .cell {
  padding-left: 20px;
  padding-right: 20px;
}
</style>
